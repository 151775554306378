import React, { Component } from "react";
import { ChaloReactHybridLogin } from "@chalo/chalo-react-login-libraries";
import "@chalo/chalo-react-login-libraries/dist/index.css";
import { Redirect } from "react-router-dom";
import {
  cookieKeys,
  googleProperties,
} from "../../../services/login/properties";
import { setTokenExpired } from "../../../redux/actions/authActions";
import { connect } from "react-redux";
import {
  checkUserLoginState,
  updateUserLoginState,
  Authorities,
} from "../../../services/authorization";
import Cookies from "universal-cookie";
import {
  fetchAccessToken,
  fetchLoggedInUser,
} from "../../../services/login/loginService";
import config from "../../../config";
import { messages } from "../../../Constant";
import { MessageType } from "../../../Constant";
import { AuthenticationService } from "../../../services/authorization";
import { Notification as NotificationComponent } from "../../../shared/widgets";
class LoginComponent extends Component {
  cookies = new Cookies();
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      logoConfig:null,
      googleProperties:googleProperties
    };
    this.userCookie = this.cookies.get(config.cookieKeys.user);
    this.fetchAccessToken = this.fetchAccessToken.bind(this);
  }

  /**
   * Used to show Notifications
   * @param  type Type of Notification
   * @param  msg   Message to Show
   */
  _addNotification(type, msg) {
    this._notificationSystem._addNotification(type, msg);
  }

  componentDidMount() {
    /**
     * Used to get refrence of notificationSystem into "this".
     */
    if(window.location.href.includes('crut')) {
      this.setState({
        logoConfig:{
        backgroundImage: `url('../../../assets/images/crut_logo.png')`,
        height: '100px',
        width: '110px',
        margin: 'auto',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        alignSelf: 'center'
      },
      googleProperties:{
        ...googleProperties,
        google: {
          ...googleProperties.google,
          redirectUri: window.location.origin
        }
      }
    })
    }
    this._notificationSystem = this.refs.notificationSystem;
    this.mounted = true;
    this.props.setTokenExpired(false);
    if (this.userCookie) {
      this.setState(
        {
          userName: this.userCookie,
        },
        () => {
          this.signInthroughUsername();
        }
      );
    }
  }

  signInthroughUsername() {
    fetchLoggedInUser(this.state.userName)
      .then((response) => {
        if (response.status != "UNAUTHORIZED") {
          this.fetchAccessToken(response.username, response.password);
        }
      })
      .catch((err) => {
        AuthenticationService.destroyInstance();

        this._addNotification(
          MessageType.ERROR,
          err.description ||
            err.error_description ||
            err.message ||
            messages.user_info_error
        );
      });
  }

  componentWillMount() {
    this.mounted = false;
    let isUserLoggedIn = checkUserLoginState();
    if (!isUserLoggedIn || isUserLoggedIn === "null") {
      this.setState({ redirectToReferrer: false, redirectTo: "/login" });
    } else {
      this.setState({ redirectToReferrer: true, redirectTo: "/" });
    }
  }

  fetchAccessToken(userName, password) {
    fetchAccessToken(userName, password)
      .then((response) => {
        let token = response.access_token;
        let refresh_token = response.refresh_token;
        this.updateLoginState(token, refresh_token);
      })
      .catch((err) => {
        AuthenticationService.destroyInstance();
        this._addNotification(
          MessageType.ERROR,
          err.description ||
            err.error_description ||
            err.message ||
            messages.auth_token_error
        );
      });
  }

  render() {
    const { redirectToReferrer, redirectTo } = this.state;
    if (redirectToReferrer) {
      return <Redirect to={redirectTo} />;
    }
    return (
      <>
        <ChaloReactHybridLogin
          hasMobileLogin={false}
          hasUserLogin={true}
          googleProperties={this.state.googleProperties}
          cookieKeys={cookieKeys}
          onLoginResponse={(event) => {
            console.log("Login Response ", event);
            if(event.data.access_token && event.data.refresh_token){
              this.updateLoginState(
                event.data.access_token,
                event.data.refresh_token
              );
            }
            else{
              this._addNotification(
                MessageType.ERROR,
                event.data.response.data.error_description,
                event.description ||
                event.error_description ||
                event.message ||
                event.auth_token_error
              );
            }
          }}
          logoConfig={this.state.logoConfig}
        />
        <div>
          <div className=" body-container">
            <NotificationComponent ref="notificationSystem" />
          </div>
        </div>
      </>
    );
  }
  updateLoginState(token, refresh_token) {
    updateUserLoginState(token, refresh_token)
      .then((authorities) => {
        let redirectUrl = '';
        if(authorities[Authorities.Owner] || authorities[Authorities.Admin] || authorities[Authorities.Manager])
        {
          redirectUrl = '/';
        }else if(authorities[Authorities.Operation]){
          redirectUrl = '/devices';
        }else if(authorities[Authorities.Partner]){
          redirectUrl = '/map';
        }else if(authorities[Authorities.BSD]){
          redirectUrl = '/BSD/detail';
        }
        this.setState({ redirectToReferrer: true, redirectTo: redirectUrl });
      })
      .catch((err) => {
        if (this.mounted) {
          console.log("error", err);
        }
      });
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setTokenExpired: (value) => {
      dispatch(setTokenExpired(value));
    },
  };
};
export default connect(null, mapDispatchToProps)(LoginComponent);

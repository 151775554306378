import React from "react";
import MenuList from "./Menu-List";
import "./sidebar.css";
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crutCities : ["berhampur","bhubaneswar","rourkela","sambalpur"]
    }
  }
  checkCrutCity = () => {
    const {crutCities} = this.state;
    const managerRoleCities = this.props.role['Manager'];
    if(managerRoleCities && managerRoleCities.length > 0){
      return managerRoleCities.some((city) => {
        if(crutCities.includes(city)){
          return true;
        }
        return false;
      })
    }
    return false;
  }

  isPathContainCrut = () => {
    return window.location.origin.includes("crut");
  }

  render() {
    return (
      <div
        id="wrapper"
        className="content"
        onMouseOver={this.props.SideBarShow}
        onMouseLeave={this.props.SideBarDontShow}
      >
        <div className="logo">
          <span className="small-nav-handle" onClick={this.props.toggle}>
            <div className="icon-container">
              <span className="sidebar-icon menu-icon" />
            </div>
          </span>
          {!this.isPathContainCrut() && <span className="logo-icon"></span>}
          {this.isPathContainCrut() && <span className="crut-logo-icon"></span>}
        </div>
        <MenuList
          toggleShow={this.props.toggleShow}
          SideBarDontShow={this.props.SideBarDontShow}
          toggle={this.props.toggle}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default SideBar;
